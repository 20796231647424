import { Redirect, Route } from "react-router-dom";
import { useUser } from "src/contexts/userContex";
import { HQLoader } from "src/views/components/reusable/loader/HQLoader";

export function PrivateRoute({ render }) {
  const { currentUser, loading } = useUser();

  if (loading) return <HQLoader />;
  return (
    <Route
      render={({ location }) =>
        currentUser?.email ? (
          render()
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
