import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SWRConfig } from "swr";
import { fetcher } from "./api/swr/request";
import { PrivateRoute } from "./privateRoute/PrivateRoute";
import "./scss/style.scss";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import ResetPassword from "./views/pages/fogotPassword/ResetPassword";
import { TestComponent } from "./views/v2/components/TestComponents/TestComponent";
import { MantineProvider } from "@mantine/core";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./views/components/layout/TheLayout")
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/fogotPassword/ForgotPassword")
);

export const App = () => {
  return (
    <>
      <MantineProvider
        withCSSVariables
        withNormalizeCSS
        theme={{
          colorScheme: "light",
          colors: {
            dark: ["#171431"],
            primary: ["#6808FF"]
          },
        }}
      >
        <SWRConfig
          value={{
            fetcher: fetcher,
          }}
        >
          <Toaster
          // toastOptions={{
          //   duration: 2000,
          //   loading: {
          //     duration: 5000,
          //   },
          // }}
          />
          <Router>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/test"
                  name="Test Page"
                  render={() => <TestComponent />}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                )
                <Route
                  exact
                  path="/register/:code"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/forgot-password"
                  name="Forgot Password Page"
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  exact
                  path="/forgot-password/:code"
                  name="Forgot Password Page"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <PrivateRoute
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </Router>
        </SWRConfig>
      </MantineProvider>
    </>
  );
};

export default App;
