import { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "src/api";

export const UserContext = createContext();

export function UserWrapper({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  // Auth persistance
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("ph_token");
        if (token) {
          // setCurrentUser(JSON.parse(localStorage.getItem("userInfo")));
          const { data } = await getUser();

          setCurrentUser(data.data);
        } else {
          setCurrentUser({});
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const sharedState = {
    loading,
    currentUser,
    setCurrentUser,
  };

  return (
    <UserContext.Provider value={sharedState}>{children}</UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
