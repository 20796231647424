import convert from "color-convert";
import React, { useState } from "react";

/**
 * Style props for button
 * @prop {function} onClick :Button functionality.
 * @prop {string} variant :Type of the button (fill or outline).
 * @prop {string} color :Background color Ex. primary-100, secondary-100.
 * @prop {string} size :Size of button 'small, medium, large, small-wide, medium-wide, large-wide'.
 * @prop {React Components} Icon :Button icon. (Optional)
 * @prop {string} children :Button Text. If you want to use icon button don't pass any children
 * @prop {boolean} Disabled :To disable the button.
 * @prop {string} className :For using bootstrap classes. (RECOMMENDATION: try to avoid using classes except margin Ex. m-2 my-3 )
 * @prop {string} textColor :Text Color of button (Only provide if needed).
 * @prop {string} Type :Button Type Ex. type="submit"
 */

const Button = ({
  variant = "fill",
  color = "primary-100",
  textColor = "white",
  size = "small",
  className = "",
  Icon,
  children,
  onClick,
  type = "button",
  disabled = false,
}) => {
  const [hover, setHover] = useState(false);
  const selectedColor = color.replace("-", "");

  //* Button colors,
  //TODO Add more to object if needed

  const colors = {
    primary100: "#6808ff",
    primary50: "#b17fff",
    secondary100: "#ff136f",
    secondary50: "#ff72a9",
    information: "#4289ff",
    warning: "#ffd019",
    error: "#ff0000",
    success: "#02b254",
    white: "#ffffff",
    black: "#000000",
    gray2: "#d6d6d6",
  };

  //* To convert the color to hsl
  const convertedColor = convert.hex.hsl(colors[selectedColor]);

  //* To set fill button color
  const fillHoverColor = `hsl(${convertedColor[0]}, ${convertedColor[1]}%, ${
    convertedColor[2] - 10
  }%)`;

  const fillButtonColor = hover
    ? fillHoverColor
    : disabled
    ? colors.gray2
    : colors[selectedColor];
  //* End

  //* To set outline button color
  const outlineHoverColor = `hsl(${convertedColor[0]}, ${convertedColor[1]}%, ${
    convertedColor[2] + 40
  }%)`;

  const outlineButtonColor = hover ? outlineHoverColor : "transparent";
  //* End

  //* Button size identifier
  let sizeIndex;

  switch (size) {
    case "small":
      sizeIndex = 0;
      break;
    case "medium":
      sizeIndex = 1;
      break;
    case "large":
      sizeIndex = 2;
      break;
    case "small-wide":
      sizeIndex = 3;
      break;
    case "medium-wide":
      sizeIndex = 4;
      break;
    case "large-wide":
      sizeIndex = 5;
      break;

    default:
      sizeIndex = 0;
  }

  //* Button size list
  //TODO Add more to array if needed.
  // const sizeCategory = [
  //   { height: "40px", padding: "0 12px" },
  //   { height: "44px", padding: "0 14px" },
  //   { height: "48px", padding: "0 16px" },
  //   { height: "40px", padding: "0 60px" },
  //   { height: "44px", padding: "0 60px" },
  //   { height: "48px", padding: "0 60px" },
  // ];

  // const commonStyles = {
  //   borderRadius: "8px",
  //   fontSize: "1rem",
  //   outline: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // };

  const fillButtonStyle = {
    backgroundColor: fillButtonColor,
    color: colors[textColor],
    border: "none",
    // ...sizeCategory[sizeIndex],
    // ...commonStyles,
  };

  const outLineButtonStyle = {
    border: `1px solid ${colors[selectedColor]}`,
    backgroundColor: outlineButtonColor,
    color: colors[selectedColor],
    // ...sizeCategory[sizeIndex],
    // ...commonStyles,
  };

  const buttonStyle = variant === "fill" ? fillButtonStyle : outLineButtonStyle;

  return (
    <button
      className={`${className} btn`}
      style={buttonStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {Icon && (
        <Icon
          className="p-0"
          color={variant.trim() === "outline" ? colors[selectedColor] : "white"}
        />
      )}
      <span
        style={Icon && children ? { marginLeft: "8px" } : { marginLeft: "0" }}
      >
        {children}
      </span>
    </button>
  );
};

export default Button;
