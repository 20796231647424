import React from "react";
import Lottie from "react-lottie";
import loading from "./loader.json";

export const HQLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{ height: "100vh", display: "flex", alignItems: "center" }}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};
