import queryString from "query-string";
function insertQuery(base, query) {
  const hasParam = isEmpty(query) ? "" : "?";
  if (process.env.NODE_ENV !== "production") {
    // console.log(query);
    // console.log(
    //   queryString.stringify(query, {
    //     skipEmptyString: true,
    //     skipNull: true,
    //   })
    // );
  }
  return (
    String(base) +
    hasParam +
    queryString.stringify(query, {
      skipEmptyString: true,
      skipNull: true,
    })
  );
}
function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
export default insertQuery;
