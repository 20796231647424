import React from "react";

export const CrossIcon = ({
  color = "#FF0000",
  width = "14",
  height = "14",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66979 -0.000793457H14.3398C17.7298 -0.000793457 19.9998 2.37921 19.9998 5.91921V14.0902C19.9998 17.6202 17.7298 19.9992 14.3398 19.9992H5.66979C2.27979 19.9992 -0.000213623 17.6202 -0.000213623 14.0902V5.91921C-0.000213623 2.37921 2.27979 -0.000793457 5.66979 -0.000793457ZM13.0098 12.9992C13.3498 12.6602 13.3498 12.1102 13.0098 11.7702L11.2298 9.99021L13.0098 8.20921C13.3498 7.87021 13.3498 7.31021 13.0098 6.97021C12.6698 6.62921 12.1198 6.62921 11.7698 6.97021L9.99979 8.74921L8.21979 6.97021C7.86979 6.62921 7.31979 6.62921 6.97979 6.97021C6.63979 7.31021 6.63979 7.87021 6.97979 8.20921L8.75979 9.99021L6.97979 11.7602C6.63979 12.1102 6.63979 12.6602 6.97979 12.9992C7.14979 13.1692 7.37979 13.2602 7.59979 13.2602C7.82979 13.2602 8.04979 13.1692 8.21979 12.9992L9.99979 11.2302L11.7798 12.9992C11.9498 13.1802 12.1698 13.2602 12.3898 13.2602C12.6198 13.2602 12.8398 13.1692 13.0098 12.9992Z"
        fill={color}
      />
    </svg>
  );
};
