import toast from "react-hot-toast";

const successAlert = (text = "Success!") =>
  toast(text, {
    style: {
      backgroundColor: "#02b254",
      color: "#fff",
    },
  });

export default successAlert;
