import LogoV2 from "src/assets/images/Group 9308.png";
import LogoIcon from "src/assets/images/Logo.png";

const Navbar = () => {
  return (
    <nav className="navbar-container">
      <div className="navbar-logo-container">
        <div className="desktop-logo">
          {/* <Logo color="white" width="230px" height="60" viewBox="0 0 250 50" /> */}
          <img src={LogoV2} alt="" />
        </div>
        <div className="phone-logo">
          <img src={LogoIcon} alt="" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
