import toast from "react-hot-toast";

const errorAlert = (text = "Something went wrong!", toastId = "") =>
  toast(text, {
    style: {
      backgroundColor: "#ad1616",
      color: "#fff",
    },
    id : toastId,
  });

export default errorAlert;
